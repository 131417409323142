import React from "react"
import { graphql, Link } from "gatsby"
import styled from "@emotion/styled/macro"
import { Seo } from "../components/Seo"
import { Layout } from "../components/Layout"
import { theme, device } from "../components/Theme"
import {
  useBlogOverviewBlogsData,
  useBlogOverviewHeroData,
} from "../components/DataImports"
import { BlogOverviewHero } from "../components/BlogOverviewHero"
import { Container } from "../components/Container"
import { BlogPostCard } from "../components/BlogPostCard"
import SvgBlogOverviewLeftArrow from "../images/SVGs/BlogOverviewLeftArrow"
import SvgBlogOverviewRightArrow from "../images/SVGs/BlogOverviewRightArrow"

const BlogIndex = ({
  data,
  pageContext: { totalPages, currentPage, previousPagePath, nextPagePath },
}) => {
  const BlogOverviewContainer = styled.div`
    margin: 10.125rem 0 6.625rem 0;

    @media ${device.lg} {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 5.125rem 2.5625rem;
    }
  `
  const PaginationContainer = styled.div`
    margin-bottom: 9.125rem;
    display: flex;
    justify-content: center;

    .paginationMobile {
      display: flex;
      column-gap: 1.1875rem;
      align-items: center;
      @media ${device.lg} {
        display: none;
      }
    }
    .paginationDesktop {
      display: none;
      @media ${device.lg} {
        display: block;
        display: flex;
        column-gap: 1.1875rem;
        align-items: center;
      }
    }
    .pageNum {
      color: ${theme.colors.paginationGray};
      padding: 1.1875rem;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid ${theme.colors.paginationGray};
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .active {
      color: ${theme.colors.light};
      border: 1px solid ${theme.colors.primary};
      background-color: ${theme.colors.primary};
    }
  `
  const heroData = useBlogOverviewHeroData()
  const blogsData = useBlogOverviewBlogsData(data)

  const posts = data.allWpPost.nodes

  let currentPage2 = currentPage

  let endPagination =
    currentPage === totalPages
      ? totalPages
      : 3 * (Math.floor(currentPage / 4) + 1)
  endPagination = endPagination >= totalPages ? totalPages : endPagination
  let startPagination = endPagination - 2
  startPagination = startPagination <= 1 ? 1 : startPagination

  let endPagination2 =
    currentPage2 === totalPages
      ? totalPages
      : 6 * (Math.floor(currentPage / 7) + 1)
  endPagination2 = endPagination2 >= totalPages ? totalPages : endPagination2
  let startPagination2 = endPagination2 - 5
  startPagination2 = startPagination2 <= 1 ? 1 : startPagination2

  const range = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    )
  const pagination = range(startPagination, endPagination, 1)

  const range2 = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    )
  const pagination2 = range2(startPagination2, endPagination2, 1)

  if (!posts.length) {
    return (
      <p>
        No blog posts found. Add posts to your WordPress site and they'll appear
        here!
      </p>
    )
  }

  return (
    <>
      <Layout data={data}>
        <Container>
          <BlogOverviewHero data={heroData} />
          <BlogOverviewContainer>
            {blogsData.map(blog => {
              return <BlogPostCard data={blog} />
            })}
          </BlogOverviewContainer>

          <PaginationContainer>
            <div className="paginationNumbersContainer">
              <div className="paginationMobile">
                {pagination.map((number, index) => (
                  <Link
                    key={index}
                    to={number === 1 ? `/blog/` : `/blog/` + number}
                    className={
                      number === currentPage ? "active pageNum" : "pageNum"
                    }
                  >
                    <span>{number}</span>
                  </Link>
                ))}
              </div>
              <div className="paginationDesktop">
                {previousPagePath !== null && (
                  <Link to={previousPagePath}>
                    <SvgBlogOverviewLeftArrow />
                  </Link>
                )}
                {pagination2.map((number, index) => (
                  <Link
                    key={index}
                    to={number === 1 ? `/blog/` : `/blog/` + number}
                    className={
                      number === currentPage ? "active pageNum" : "pageNum"
                    }
                  >
                    <span>{number}</span>
                  </Link>
                ))}
                {nextPagePath !== null && (
                  <Link to={nextPagePath}>
                    <SvgBlogOverviewRightArrow />
                  </Link>
                )}
              </div>
            </div>
            {currentPage !== totalPages ? (
              <Link to={`/blog/` + (currentPage + 1)}>
                <div className="paginationNext" color={theme.colors.tertiary} />
              </Link>
            ) : (
              <div className="paginationNext" color={theme.colors.neutral} />
            )}
          </PaginationContainer>
        </Container>
      </Layout>

      <Seo page="blogIndexPage" />
    </>
  )
}

export const blogPageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP, AVIF])
              }
            }
          }
        }
        featuredImageAltText {
          featuredImageAltText
        }
        date(formatString: "MMM DD, YYYY")
        title
        excerpt
        link
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
export default BlogIndex
