import * as React from "react"

const SvgHeroRed = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={1113.333}
    height={694.333}
    viewBox="0 0 1113.333 694.333"
    {...props}
  >
    <path
      d="M1.941 191h905.1l208.234 694.333L1.941 881.345Z"
      transform="translate(-1.941 -191)"
      fill="#de292a"
      style={{
        mixBlendMode: "multiply",
        isolation: "isolate",
      }}
    />
  </svg>
)

export default SvgHeroRed
