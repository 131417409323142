import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from "@emotion/styled/macro"
import { theme } from './Theme'

const BlogPostCard = (props) =>{
    const BlogPostCardContainer = styled(Link)`
    color: inherit;
    display: block;
    box-shadow: 5px 10px 20px ${theme.colors.gray}, -5px 10px 20px ${theme.colors.gray};
    &:hover, &:active{
        color: ${theme.colors.primary};
    }
    .imageDateContainer{
        position: relative;
    }
    .date{
        position: absolute;
        bottom: -17px;
        color: ${theme.colors.light};
        padding: 0.375rem 1.3125rem;
        margin: 0 2.375rem !important;
        background-color: ${theme.colors.primary};
        font-size: 14px;
        font-weight: 700;
        font-family: ${theme.fonts.altFont};
        text-transform: uppercase;
        letter-spacing: 1px;
    }
    .textContainer{
        margin: 3.25rem 2.375rem;
    }
    .title{
        margin: 0;
        font-size: 29px;
        font-weight: 700;
    }
    .excerpt{
        padding-bottom: 2rem;
        font-size: clamp(1rem, 0.9242rem + 0.3236vw, 1.3125rem);
    line-height: clamp(1.625rem, 1.5492rem + 0.3236vw, 1.9375rem);
    margin: 0.96875rem 0;
    }
    `
    return(
        <BlogPostCardContainer to={`/blog`+props.data.link}>
        <div className="imageDateContainer">
<GatsbyImage image={props.data.featuredImage} alt={props.data.altText} />
<p className="date">
    {props.data.datePublished}
</p>
        </div>
        <div className="textContainer">
            <p className="title">
                {props.data.title}
            </p>
            <div className="excerpt" dangerouslySetInnerHTML={{__html: props.data.excerpt}}  />
        </div>
        </BlogPostCardContainer>
    )
}
export {BlogPostCard}