import * as React from "react"

const SvgHeroMobileDarkRedShape = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={441.355}
    height={338.312}
    viewBox="0 0 441.355 338.312"
    {...props}
  >
    <path
      d="M2419 204.292h441.355V417.16L2419 542.6Z"
      transform="translate(-2419 -204.292)"
      fill="#5a0000"
      style={{
        mixBlendMode: "multiply",
        isolation: "isolate",
      }}
    />
  </svg>
)

export default SvgHeroMobileDarkRedShape
