import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "@emotion/styled/macro"
import { theme, device } from "../components/Theme"
import SvgHeroMobileDarkRedShape from "../images/SVGs/HeroMobileDarkRedShape"
import SvgHeroRed from "../images/SVGs/HeroRed"

const BlogOverviewHero = props => {
  const BlogOverviewHeroContainer = styled.div`
    display: grid;

    .svgTextContainer {
      grid-area: 1/1;
      position: relative;
      display: grid;
    }
    .mobileRedShape {
      width: 100%;
      grid-area: 1/1;
      max-width: 100%;
      @media ${device.lg} {
        display: none;
      }
    }
    .desktopRedShape {
      display: none;

      @media ${device.lg} {
        display: block;
        grid-area: 1/1;
        max-width: 58%;
        height: 100%;
      }
    }
    .textContainer {
      grid-area: 1/1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      gap: 3.125em;
      color: ${theme.colors.light};
      width: 100%;
      padding: 0 2rem;
      margin: 0 auto;
      height: 100%;
      box-sizing: border-box;

      @media (min-width: 400px) {
        max-width: 90.75rem;
      }
      @media ${device.lg} {
        text-align: left;
      }
      p {
        line-height: 1.5;
        max-width: 680px;
        font-size: clamp(1rem, 0.9393rem + 0.2589vw, 1.25rem);
      }
      .blogOverviewTitle {
        font-size: clamp(2rem, 1.6966rem + 1.2945vw, 3.25rem);
        font-weight: 800;
        text-transform: uppercase;
        max-width: 720px;
        line-height: 1.2;
      }
    }
  `
  const GatsbyImageStyled = styled(GatsbyImage)`
    transition: none;
    pointer-events: none;
    grid-area: 1/1;
  `
  return (
    <BlogOverviewHeroContainer>
      <GatsbyImageStyled
        image={props.data.backgroundImage}
        alt={props.data.altText}
      />
      <div className="svgTextContainer">
        <SvgHeroMobileDarkRedShape
          className="mobileRedShape"
          preserveAspectRatio="none"
        />
        <SvgHeroRed className="desktopRedShape" preserveAspectRatio="none" />
        <div className="textContainer">
          <div className="textContainerInner">
            <p className="blogOverviewTitle">{props.data.title}</p>
            <p>{props.data.text}</p>
          </div>
        </div>
      </div>
    </BlogOverviewHeroContainer>
  )
}
export { BlogOverviewHero }
