import * as React from "react"

const SvgBlogOverviewLeftArrow = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={61.204}
    height={22.415}
    viewBox="0 0 61.204 22.415"
    {...props}
  >
    <g fill="none" stroke="#707070" strokeLinecap="round" strokeWidth={3}>
      <path data-name="Line 1" d="m12.344 2.118-10.226 9.09" />
      <path data-name="Line 2" d="m12.344 20.298-10.226-9.09" />
      <path data-name="Line 3" d="M59.704 11.208H3.254" />
    </g>
  </svg>
)

export default SvgBlogOverviewLeftArrow
